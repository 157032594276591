"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PasswordStrength = void 0;
var badPasswords = require("popular-passwords");
var PasswordStrength = function () {
  function PasswordStrength() {
    (0, _classCallCheck2.default)(this, PasswordStrength);
  }
  return (0, _createClass2.default)(PasswordStrength, null, [{
    key: "checkPassword",
    value: function checkPassword(pw) {
      var min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 8;
      var minExcellent = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 30;
      var score = 0;
      var noScore = false;
      var scoreComponents = [];
      if (pw.length < min) {
        noScore = true;
      }
      var lengthScore = Math.floor(pw.length / 8 * 100) / 1000;
      score += lengthScore;
      scoreComponents.push(lengthScore);
      if (this.isKnownInsecurePassword(pw)) {
        noScore = true;
      }
      if (/[a-z]/.test(pw)) {
        var match = pw.match(/[a-z]/g);
        var lowerCaseScore = match != null && match.length > 1 ? 0.2 : 0.1;
        score += lowerCaseScore;
        scoreComponents.push(lowerCaseScore);
      } else {
        scoreComponents.push(0);
      }
      if (/[A-Z]/.test(pw)) {
        var _match = pw.match(/[A-Z]/g);
        var upperCaseScore = _match != null && _match.length > 1 ? 0.2 : 0.1;
        score += upperCaseScore;
        scoreComponents.push(upperCaseScore);
      } else {
        scoreComponents.push(0);
      }
      if (/[0-9]/.test(pw)) {
        var _match2 = pw.match(/[0-9]/g);
        var numberScore = _match2 != null && _match2.length > 1 ? 0.2 : 0.1;
        score += numberScore;
        scoreComponents.push(numberScore);
      } else {
        scoreComponents.push(0);
      }
      if (/[^0-9a-zA-Z]/.test(pw)) {
        var _match3 = pw.match(/[^0-9a-zA-Z]/g);
        var markScore = _match3 != null && _match3.length > 1 ? 0.2 : 0.1;
        score += markScore;
        scoreComponents.push(markScore);
      } else {
        scoreComponents.push(0);
      }
      if (score >= 1) {
        score = 1;
      }
      if (pw.length < minExcellent) {
        var lengthPenalty = (minExcellent - pw.length) / (minExcellent * 10);
        score -= lengthPenalty;
        scoreComponents.push(-lengthPenalty);
      } else {
        scoreComponents.push(0);
      }
      return [noScore ? 0 : score].concat(scoreComponents);
    }
  }, {
    key: "isKnownInsecurePassword",
    value: function isKnownInsecurePassword(pw) {
      return this.byStep(pw) || this.isAsdf(pw) || badPasswords.indexOf(pw.toLocaleLowerCase()) >= 0;
    }
  }, {
    key: "byStep",
    value: function byStep(raw) {
      var delta = raw.charCodeAt(1) - raw.charCodeAt(0);
      for (var i = 0; i < raw.length - 1; i += 1) {
        if (raw.charCodeAt(i + 1) - raw.charCodeAt(i) !== delta) {
          return false;
        }
      }
      return true;
    }
  }, {
    key: "isAsdf",
    value: function isAsdf(raw) {
      var ASDF = ['qwertyuiop', 'qwertzuiop', 'asdfghjkl', 'zxcvbnm', 'yxcvbnm'];
      var reverse = raw.split('').reverse().join('');
      var asdf = ASDF.join('');
      if (asdf.indexOf(raw) >= 0 || asdf.indexOf(reverse) >= 0) {
        return true;
      }
      asdf = ASDF.reverse().join('');
      if (asdf.indexOf(raw) >= 0 || asdf.indexOf(reverse) >= 0) {
        return true;
      }
      return false;
    }
  }]);
}();
exports.PasswordStrength = PasswordStrength;