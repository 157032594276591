import { CheckboxListItem } from 'materialTheme/src/theme/components/CheckboxListItem';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { DateInput } from 'materialTheme/src/theme/components/forminput/DateInput';
import { DateRangeInput } from 'materialTheme/src/theme/components/forminput/DateRangeInput';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { MaterialTextEditableV2 } from 'materialTheme/src/theme/components/text/MaterialTextEditableV2';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CustomFieldCheckboxListOptions, CustomFieldDateOptions, CustomFieldDateRangeOptions, CustomFieldListOptions, CustomFieldMultiselectListOptions, CustomFieldNumberOptions, CustomFieldStringOptions, } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { ChangeTicketCustomField } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCustomField';
import { Divider } from 'materialTheme/src/theme/components/utils/Divider';
import { TicketDescriptionInput } from './TicketDescriptionInput';
import { TicketStatusChip } from './TicketStatusChip';
import { I18n } from '../../../i18n/I18n';
import { PersonChip } from './PersonChip';
import { MultiPersonChip } from './MultiPersonChip';
import { TicketProgress } from './TicketProgress';
import { PlanAffiliationThumbs } from './PlanAffiliationThumbs';
import { ApproverChip } from './ApproverChip';
import { AssigneeChip } from './AssigneeChip';
import { CraftChip } from './CraftChip';
import { TagChips } from './TagChips';
import { TicketCompletionDateChip } from './TicketCompletionDateChip';
export const CustomFields = React.memo((props) => {
    const projectTickets = useRef([]);
    const [canEditAllOrCreator, setCanEditAllOrCreator] = useState(true);
    const [project, setProject] = useState(null);
    useEffect(() => {
        UpmeshClient.instance.modals.project
            .getById(props.projectId)
            .then((result) => {
            setProject(result);
        })
            .catch((err) => {
            console.error('could not get project', err);
        });
    }, [props.projectId, props.ticketId]);
    useEffect(() => {
        if (CurrentUser.userId === props.ticket.creator) {
            setCanEditAllOrCreator(true);
        }
        else {
            RightsManager.hasWriteRight(props.projectId, CurrentUser.userId, 'canChangeAllTickets')
                .then((canChangeAll) => {
                if (canChangeAll === true) {
                    setCanEditAllOrCreator(true);
                }
                else {
                    setCanEditAllOrCreator(false);
                }
            })
                .catch((_err) => {
                setCanEditAllOrCreator(false);
            });
        }
    }, [props.ticket.creator, props.ticketId, props.projectId]);
    useEffect(() => {
        let query = `projectId eq '${props.projectId}' and deleted ne true and type eq null`;
        if (props.ticketLayout.onTypes != null) {
            query = `projectId eq '${props.projectId}' and deleted ne true and type in ${JSON.stringify(props.ticketLayout.onTypes)}`;
        }
        UpmeshClient.instance.modals.ticket
            .get({
            filter: query,
        })
            .then((result) => {
            projectTickets.current = result;
        })
            .catch((err) => {
            console.error('could not get project tickets for autocomplete', err);
        });
    }, []);
    const getAutoCompleteOptions = (fieldId) => {
        const options = new Set();
        projectTickets.current.forEach((t) => {
            const val = t.fields?.find((a) => a.id === fieldId)?.value;
            if (val)
                options.add(val);
        });
        return [...options.values()];
    };
    const handleSystemFields = (l) => {
        const newFields = [];
        if (project == null || props.ticketLayout == null) {
            return newFields;
        }
        if (l.systemField === 'description') {
            newFields.push(<TicketDescriptionInput key={`${project.id}_${props.ticketId}_description_${props.ticket.description}`} ticket={props.ticket} errorResult={props.errorResult}/>);
        }
        else if (l.systemField === 'status') {
            newFields.push(<TicketStatusChip key={`${project.id}_${props.ticketId}_status_${props.ticket.ticketStatus}`} project={project} labelStyle={props.labelStyle} ticket={props.ticket} errorResult={props.errorResult} ticketId={props.ticketId}/>);
        }
        else if (l.systemField === 'plan') {
            newFields.push(<PlanAffiliationThumbs key={`${project.id}_${props.ticket.id}_plan_${props.ticket.planId}`} labelStyle={props.labelStyle} planId={props.ticket.planId ?? undefined} planPositionX={props.ticket.planPositionX ?? undefined} planPositionY={props.ticket.planPositionY ?? undefined} ticketAddress={props.ticket.address} ticketId={props.ticketId} ticketStatus={props.ticket.ticketStatus} completionOn={props.ticket.completionOn ?? undefined} projectId={props.projectId}/>);
        }
        else if (l.systemField === 'approver') {
            newFields.push(<ApproverChip key={`${project.id}_${props.ticket.id}_approver_${props.ticket.approverUserId}`} labelStyle={props.labelStyle} approverUserId={props.ticket.approverUserId ?? undefined} ticketId={props.ticketId} projectId={props.projectId} errorResult={props.errorResult}/>);
        }
        else if (l.systemField === 'assignee') {
            newFields.push(<AssigneeChip key={`${project.id}_${props.ticket.id}_assignee_${props.ticket.assignedToUserId}`} labelStyle={props.labelStyle} errorResult={props.errorResult} assignedToUserId={props.ticket.assignedToUserId ?? undefined} projectId={props.projectId} ticketId={props.ticketId}/>);
        }
        else if (l.systemField === 'craft') {
            newFields.push(<CraftChip key={`${project.id}_${props.ticket.id}_craft_${props.ticket.craft}`} labelStyle={props.labelStyle} projectId={props.projectId} ticketId={props.ticketId} craft={props.ticket.craft ?? undefined} errorResult={props.errorResult}/>);
        }
        else if (l.systemField === 'tags') {
            newFields.push(<TagChips key={`${project.id}_${props.ticket.id}_tags_${props.ticket.tags && props.ticket.tags.join(',')}`} labelStyle={props.labelStyle} projectId={props.projectId} ticketId={props.ticketId} ticketTags={props.ticket.tags ?? undefined} errorResult={props.errorResult}/>);
        }
        else if (l.systemField === 'completionOn') {
            newFields.push(<TicketCompletionDateChip key={`${project.id}_${props.ticketId}_completionon_${props.ticket.completionOn}`} labelStyle={props.labelStyle} errorResult={props.errorResult} completionOn={props.ticket.completionOn ?? undefined} ticketStatus={props.ticket.ticketStatus} ticketId={props.ticketId} hideTime={l.systemFieldOptions != null && l.systemFieldOptions['hideTime']}/>);
        }
        return newFields;
    };
    const renderCustomField = (l, field, labelStyle, editable, errorResult) => {
        const newFields = [];
        if (l.customField) {
            if (l.customField.type === 'number') {
                const o = new CustomFieldNumberOptions(l.customField.options);
                const value = field && field.value != null ? field.value : o.default ? o.default : 0;
                const c = o.getDefaulValues(l, value);
                newFields.push(<View style={{ ...labelStyle }} key={`customField_${l.id}`}>
            <MaterialTextEditableV2 numberField={{
                        delimiter: o.delimiter === 0 ? ',' : '.',
                    }} toolTip={l.description} label={l.label} key={`customNumber_${l.id}`} materailTextProps={{
                        type: MaterialTextTypes.Body1,
                        recognizeUrl: false,
                        numberOfLines: 1,
                    }} onChanged={(v) => {
                        if (props.ticketId != null) {
                            const c = new ChangeTicketCustomField({ fieldId: l.id, value: Number.parseFloat(v) }, props.ticketId);
                            c.execute().catch((err) => errorResult(err));
                        }
                    }} placeholder={l.description} editable={editable}>
              {c.formattedValue}
            </MaterialTextEditableV2>
          </View>);
            }
            else if (l.customField.type === 'List') {
                const o = new CustomFieldListOptions(l.customField.options);
                const value = field && field.value != null ? field.value : o.default ? o.default : '';
                const chipList = [];
                o.list?.forEach((item) => {
                    chipList.push({
                        title: item,
                        onPressChipData: item,
                    });
                });
                const i = chipList.findIndex((a) => a.onPressChipData.trim() === value.trim());
                const selectedIndex = i >= 0 ? i : -1;
                newFields.push(<View style={{ ...labelStyle }} key={`customField_${l.id}`}>
            <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
              {l.label}
            </MaterialText>
            <OpenableChip key={`customField_chip__${l.id}`} dialogTitle={l.label} showDeleteIcon accessibilityLabel={l.label} elevation={40} selected={selectedIndex} onPressChip={(d) => {
                        if (props.ticketId != null) {
                            const c = new ChangeTicketCustomField({ fieldId: l.id, value: d }, props.ticketId);
                            c.execute().catch((err) => errorResult(err));
                        }
                    }} widthOpen={256} textColor={ThemeManager.style.black87} chipsList={chipList} chipDisabled={!editable}/>
          </View>);
            }
            else if (l.customField.type === 'MultiselectList') {
                const o = new CustomFieldMultiselectListOptions(l.customField.options);
                const value = field && field.value != null ? field.value : o.default ? o.default : [];
                const chipList = [];
                o.list?.forEach((item) => {
                    chipList.push({
                        title: item,
                        id: item,
                    });
                });
                const selectedChips = [];
                value.forEach((v) => {
                    selectedChips.push({
                        title: v,
                        id: v,
                    });
                });
                newFields.push(<View style={{ ...labelStyle }} key={`customField_${l.id}`}>
            <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
              {l.label}
            </MaterialText>
            <ChipGroup dialogTitle={l.label} chips={selectedChips} bordered={false} availableChips={chipList} onChanged={(d) => {
                        if (props.ticketId == null)
                            return;
                        const value = [];
                        d.forEach((v) => {
                            if (v.id)
                                value.push(v.id);
                        });
                        const c = new ChangeTicketCustomField({ fieldId: l.id, value }, props.ticketId);
                        c.execute().catch((err) => props.errorResult(err));
                    }} key={`added_${editable}_${selectedChips.toString()}`} buttonDisabled={!editable} displayDisabledButtons={false} editable={editable} tooltipAddChip={I18n.m.getMessage('ticketsDetailsAddWatcher')}/>
          </View>);
            }
            else if (l.customField.type === 'CheckboxList') {
                const o = new CustomFieldCheckboxListOptions(l.customField.options);
                const value = field && field.value != null ? field.value : o.default ? o.default : [];
                const chipList = [];
                o.list?.forEach((item) => {
                    chipList.push({
                        title: item,
                        id: item,
                    });
                });
                const selectedChips = new Set();
                value.forEach((v) => {
                    selectedChips.add(v);
                });
                newFields.push(<View style={{ ...labelStyle }} key={`customField_${l.id}`}>
            <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
              {l.label}
            </MaterialText>
            {chipList.map((item) => {
                        return (<CheckboxListItem disabled={!editable} title={item.title} value={!!selectedChips.has(item.title)} onChange={(v) => {
                                if (props.ticketId == null)
                                    return;
                                if (!item.id)
                                    return;
                                if (v)
                                    selectedChips.add(item.id);
                                else
                                    selectedChips.delete(item.id);
                                const value = [];
                                selectedChips.forEach((val) => {
                                    if (val)
                                        value.push(val);
                                });
                                const c = new ChangeTicketCustomField({ fieldId: l.id, value }, props.ticketId);
                                c.execute().catch((err) => props.errorResult(err));
                            }}/>);
                    })}
          </View>);
            }
            else if (l.customField.type === 'DateRange') {
                const o = new CustomFieldDateRangeOptions(l.customField.options);
                const value = field && field.value && field.value['from'] && field.value['to']
                    ? { from: new Date(field.value.from), to: new Date(field.value.to) }
                    : undefined;
                newFields.push(<View style={{ ...labelStyle }} key={`customField_${l.id}`}>
            <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
              {l.label}
            </MaterialText>
            <DateRangeInput onChange={(d) => {
                        if (props.ticketId == null)
                            return;
                        const c = new ChangeTicketCustomField({ fieldId: l.id, value: d }, props.ticketId);
                        c.execute().catch((err) => props.errorResult(err));
                    }} labelText={l.label} selectTime={o.withTime} placeholder={l.description} selectedDate={value} disabled={!editable}/>
          </View>);
            }
            else if (l.customField.type === 'Date') {
                const o = new CustomFieldDateOptions(l.customField.options);
                const value = field && field.value && field.value['date'] ? new Date(field.value['date']) : undefined;
                newFields.push(<View style={{ ...labelStyle }} key={`customField_${l.id}`}>
            <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
              {l.label}
            </MaterialText>
            <DateInput onChange={(d) => {
                        if (props.ticketId == null)
                            return;
                        const c = new ChangeTicketCustomField({ fieldId: l.id, value: { date: d } }, props.ticketId);
                        c.execute().catch((err) => props.errorResult(err));
                    }} labelText={l.label} selectDate selectTime={o.withTime} placeholder={l.description} selectedDate={value} disabled={!editable}/>
          </View>);
            }
            else if (l.customField.type === 'person') {
                newFields.push(<PersonChip key={`customField_${l.id}`} labelStyle={labelStyle} errorResult={props.errorResult} personField={l} ticketFields={props.ticket.fields} ticketId={props.ticketId} projectId={props.projectId}/>);
            }
            else if (l.customField.type === 'multiperson') {
                newFields.push(<MultiPersonChip key={`customField_${l.id}`} labelStyle={labelStyle} errorResult={props.errorResult} personField={l} ticketFields={props.ticket.fields} ticketId={props.ticketId}/>);
            }
            else if (l.customField.type === 'string') {
                const o = new CustomFieldStringOptions(l.customField.options);
                const value = field && field.value != null ? field.value : o.default ? o.default : '';
                newFields.push(<View style={{ ...labelStyle }} key={`customField_${l.id}`}>
            <MaterialTextEditableV2 toolTip={l.description} label={l.label} key={`custom_${l.id}`} materailTextProps={{
                        type: MaterialTextTypes.Body1,
                        recognizeUrl: true,
                        numberOfLines: o.multiline ? 0 : 1,
                    }} onChanged={(v) => {
                        if (props.ticketId == null)
                            return;
                        const c = new ChangeTicketCustomField({ fieldId: l.id, value: v }, props.ticketId);
                        c.execute().catch((err) => props.errorResult(err));
                    }} blurOnSubmit={!o.multiline} placeholder={l.description} editable={editable} autoCompleteList={getAutoCompleteOptions(l.id)}>
              {value}
            </MaterialTextEditableV2>
          </View>);
            }
            else if (l.customField.type === 'divider') {
                const o = l.customField.options;
                newFields.push(<Divider title={o.title} description={o.description}/>);
            }
            else if (l.customField.type === 'progress') {
                newFields.push(<TicketProgress key={`customField_${l.id}`} labelStyle={labelStyle} errorResult={props.errorResult} editable={editable} progressField={l}/>);
            }
        }
        return newFields;
    };
    const handleCustomField = (l, editable) => {
        const newFields = [];
        if (props.ticketLayout == null || l.customField == null)
            return newFields;
        const field = props.ticket.fields?.find((a) => a.id === l.id);
        newFields.push(...renderCustomField(l, field, props.labelStyle, editable ?? false, props.errorResult));
        if (l.customField.type === 'List') {
            const customFieldWithChildren = l.customField.options;
            const value = field ? field.value : '';
            if (customFieldWithChildren.children && customFieldWithChildren.children.length > 0) {
                const matchingChildren = customFieldWithChildren.children.filter((child) => child.onSelectedRule.includes(value));
                for (const child of matchingChildren) {
                    for (const childField of child.fields) {
                        const childFieldData = props.ticket.fields?.find((a) => a.id === childField.id);
                        newFields.push(...renderCustomField(childField, childFieldData, props.labelStyle, editable ?? false, props.errorResult));
                    }
                }
            }
        }
        if (l.customField.type === 'MultiselectList') {
            const customFieldWithChildren = l.customField.options;
            const value = field && field.value != null ? field.value : [];
            if (customFieldWithChildren.children && customFieldWithChildren.children.length > 0) {
                const matchingChildren = customFieldWithChildren.children.filter((child) => value.some((value) => child.onSelectedRule.includes(value)));
                for (let i = 0; i < matchingChildren.length; i += 1) {
                    if (matchingChildren) {
                        for (const l of matchingChildren[i].fields) {
                            if (l?.customField) {
                                const childField = props.ticket.fields?.find((a) => a.id === l.id);
                                newFields.push(...renderCustomField(l, childField, props.labelStyle, editable ?? false, props.errorResult));
                            }
                        }
                    }
                }
            }
        }
        return newFields;
    };
    const renderFields = () => {
        const fields = [];
        for (const l of props.ticketLayout.fields) {
            const editable = canEditAllOrCreator ||
                (l.approverCanEdit === true && props.ticket.approverUserId === CurrentUser.userId) ||
                (l.assigneeCanEdit === true && props.ticket.assignedToUserId === CurrentUser.userId);
            if (l.systemField) {
                fields.push(...handleSystemFields(l));
            }
            else if (l.customField) {
                fields.push(...handleCustomField(l, editable));
            }
        }
        return fields;
    };
    return <View style={{ width: '100%' }}>{renderFields()}</View>;
});
