import color from 'color';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { EmptyState } from 'materialTheme/src/theme/components/EmptyState';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { ScrollView, View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CachedEntities } from '../../../config/CachedEntities';
import { I18n } from '../../../i18n/I18n';
const emptyStateImage = require('../../../assets/img/no_absences.png');
export class AbsenceWidget extends PureComponent {
    constructor(props) {
        super(props);
        this.updateData = () => {
            const today = new Date();
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            const firstShownDay = yesterday.getTime() < firstDayOfMonth.getTime() ? yesterday : firstDayOfMonth;
            const asyncNow = async () => {
                const abs = await UpmeshClient.instance.modals.absence.get({
                    filter: `deleted ne true and state eq 'approved' and ends ge ${firstShownDay.toISOString()}`,
                });
                const absences = [];
                abs.forEach((a) => {
                    absences.push({
                        ...a,
                        userEntity: a.userId ? CachedEntities.knownUsers.get(a.userId) : undefined,
                        taskEntity: CachedEntities.knownTasks.get(a.taskId),
                        memberEntity: CachedEntities.knownCompanyMember.get(a.memberId),
                    });
                });
                absences.sort((a, b) => {
                    return new Date(a.starts).getTime() - new Date(b.starts).getTime();
                });
                const todayMorning = new Date();
                todayMorning.setHours(0, 0, 0, 0);
                let date = todayMorning.getTime();
                let dateTo = new Date(todayMorning.getFullYear(), todayMorning.getMonth(), todayMorning.getDate(), 23, 59, 59, 999).getTime();
                const filter = SimpleStorage.get(`absencewidgetfilter`);
                if (filter != null) {
                    const filterArray = filter.split(',');
                    const dateNumber = parseInt(filterArray[0], 10);
                    if (!isNaN(dateNumber))
                        date = dateNumber;
                    const dateToNumber = parseInt(filterArray[1], 10);
                    if (!isNaN(dateToNumber))
                        dateTo = dateToNumber;
                }
                this.calculateFiltered(absences, date, dateTo);
                this.setState({ absences, date, dateTo });
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.getTheMondayBefore = (date) => {
            if (date.getDay() === 1)
                return date;
            if (date.getDay() === 0)
                return new Date(date.getTime() - 6 * 24 * 60 * 60 * 1000);
            return new Date(date.getTime() - (date.getDay() + 1) * 24 * 60 * 60 * 1000);
        };
        this.fastSelectDate = (dates) => (_e) => {
            this.setState({ date: dates.from.getTime(), dateTo: dates.to.getTime() }, () => this.calculateFiltered(this.state.absences, dates.from.getTime(), dates.to.getTime()));
            SimpleStorage.set('absencewidgetfilter', `${dates.from.getTime()},${dates.to.getTime()}`);
        };
        this.calculateFiltered = (absences, date, dateTo) => {
            const filteredAbsences = absences.filter((a) => {
                const start = new Date(a.starts).getTime();
                const end = new Date(a.ends).getTime();
                if (end < date)
                    return false;
                if (start > dateTo)
                    return false;
                return true;
            });
            this.setState({ filteredAbsences });
        };
        this.renderChip = (start, end, name) => {
            const { date, dateTo } = this.state;
            const selected = date != null && date === start.getTime() && dateTo != null && dateTo === end.getTime();
            return (<Chip key={`${name}Timetracks${date}_${dateTo}`} backgroundColor={selected ? color(ThemeManager.style.brandPrimary).alpha(0.12).toString() : undefined} textColor={selected ? ThemeManager.style.brandPrimary : undefined} title={name} onPressChip={this.fastSelectDate({ from: start, to: end })}/>);
        };
        this.state = { absences: [], filteredAbsences: [] };
    }
    componentDidMount() {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'AbsenceWidget',
            readModelName: 'Absence',
            callback: this.updateData,
        });
        this.updateData();
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('Absence', 'AbsenceWidget');
    }
    getLastDayThisMonth() {
        const today = new Date();
        let nextMonth = today;
        if (today.getMonth() !== 11) {
            nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1, 0, 0, 0, 0);
        }
        else {
            nextMonth = new Date(today.getFullYear() + 1, 0, 0, 0, 0, 0, 0);
        }
        return new Date(nextMonth.getTime() - 1);
    }
    renderQuickfilters() {
        const todayMorning = new Date();
        todayMorning.setHours(0, 0, 0, 0);
        const today = {
            from: todayMorning,
            to: new Date(todayMorning.getFullYear(), todayMorning.getMonth(), todayMorning.getDate(), 23, 59, 59, 999),
        };
        const yesterday = {
            from: new Date(todayMorning.getTime() - 24 * 60 * 60 * 1000),
            to: new Date(todayMorning.getTime() - 1),
        };
        const thisWeek = {
            from: this.getTheMondayBefore(todayMorning),
            to: new Date(this.getTheMondayBefore(todayMorning).getTime() + 7 * 24 * 60 * 60 * 1000 - 1),
        };
        const thisMonth = {
            from: new Date(todayMorning.getFullYear(), todayMorning.getMonth(), 1, 0, 0, 0, 0),
            to: this.getLastDayThisMonth(),
        };
        return (<View style={{ flexDirection: 'row', flexWrap: 'wrap', padding: 8 }}>
        {this.renderChip(today.from, today.to, I18n.m.getMessage('timeTrackingFilterToday'))}
        {this.renderChip(yesterday.from, yesterday.to, I18n.m.getMessage('timeTrackingFilterYesterday'))}
        {this.renderChip(thisWeek.from, thisWeek.to, I18n.m.getMessage('timeTrackingFilterThisWeek'))}
        {this.renderChip(thisMonth.from, thisMonth.to, I18n.m.getMessage('timeTrackingFilterThisMonth'))}
      </View>);
    }
    renderAbsence(absence) {
        return (<View key={`absence${absence.id}`} style={{
                flexDirection: 'row',
                padding: 16,
                borderBottomWidth: ThemeManager.style.borderWidth,
                borderBottomColor: ThemeManager.style.borderColor,
                alignItems: 'center',
                height: 80,
                width: '100%',
            }}>
        <UserImage user={absence.userEntity} size={48}/>
        <View style={{ flex: 1, paddingLeft: 8 }}>
          <View>
            <MaterialText type={MaterialTextTypes.Caption}>{`${I18n.m.dateCurrent.localeDateString(new Date(absence.starts))} - ${I18n.m.dateCurrent.localeDateString(new Date(absence.ends))}`}</MaterialText>
          </View>
          <MaterialText>
            {absence.userEntity
                ? absence.userEntity.getFullName()
                : `${absence.memberEntity.firstName} ${absence.memberEntity.lastName}`}
          </MaterialText>
          <MaterialText type={MaterialTextTypes.Body2} color={ThemeManager.style.black54} numberOfLines={1}>{`${absence.taskEntity?.mark} - ${absence.taskEntity?.description}`}</MaterialText>
        </View>
      </View>);
    }
    renderAllAbsences() {
        const { filteredAbsences } = this.state;
        const rendered = [];
        filteredAbsences.forEach((a) => rendered.push(this.renderAbsence(a)));
        return <View style={{ height: filteredAbsences.length * 80 }}>{rendered}</View>;
    }
    render() {
        const { absences } = this.state;
        return (<View style={{ width: '100%', height: '100%' }}>
        <View style={{ padding: 16, paddingBottom: 0, width: '100%', height: 48 }}>
          <MaterialText type={MaterialTextTypes.H5}>{I18n.m.getMessage('absences')}</MaterialText>
        </View>
        <View>{this.renderQuickfilters()}</View>
        {absences.length > 0 ? (<ScrollView style={{ flex: 1 }} nestedScrollEnabled>
            {this.renderAllAbsences()}
          </ScrollView>) : (<EmptyState imageSrc={emptyStateImage} description={I18n.m.getMessage('absencesNoAbsencesHelper')}/>)}
      </View>);
    }
}
