import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { ChangeTicketCraft } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCraft';
import { I18n } from '../../../i18n/I18n';
export const CraftChip = React.memo((props) => {
    const [crafts, setCrafts] = useState([]);
    const [disabledChip, setDisabledChip] = useState(true);
    useEffect(() => {
        UpmeshClient.instance.modals.project
            .getById(props.projectId)
            .then((pResult) => {
            setCrafts(pResult.crafts ?? []);
        })
            .catch((err) => {
            console.debug(err);
        });
    }, [props.projectId]);
    useEffect(() => {
        if (props.ticketId != null) {
            const c = new ChangeTicketCraft({ craft: 'testCraft' }, props.ticketId);
            c.canI()
                .then((cResult) => {
                if (cResult === true) {
                    setDisabledChip(false);
                }
                else {
                    setDisabledChip(true);
                }
            })
                .catch((err) => {
                console.debug(err);
                setDisabledChip(true);
            });
        }
    }, []);
    const getCraftList = (crafts) => {
        const list = [];
        crafts?.forEach((s) => {
            list.push({
                title: s,
                onPressChipData: s,
                textColor: ThemeManager.style.defaultTextColor,
            });
        });
        list.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
        return list;
    };
    const onChangeType = (e) => {
        if (props.ticketId != null) {
            const c = new ChangeTicketCraft({ craft: e == null || e === 0 ? null : e }, props.ticketId);
            c.execute().catch((err) => {
                props.errorResult(err);
            });
        }
    };
    let currentTypeIndex = -1;
    const craftList = getCraftList(crafts);
    for (let i = 0; i < craftList.length; i += 1) {
        if (craftList[i].onPressChipData === props.craft) {
            currentTypeIndex = i;
            break;
        }
    }
    return (<View>
      <View key="craftLabel" style={props.labelStyle}>
        <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption} numberOfLines={1}>
          {I18n.m.getMessage('craft')}
        </MaterialText>
      </View>
      <OpenableChip key="craftChip" showDeleteIcon emptyStateChip={{
            title: I18n.m.getMessage('ticketsCraftNotSelected'),
            onPressChipData: 0,
            textColor: ThemeManager.style.defaultTextColor,
        }} dialogTitle={I18n.m.getMessage('craft')} accessibilityLabel="craft" elevation={40} selected={currentTypeIndex} onPressChip={onChangeType} widthOpen={256} chipsList={craftList} chipDisabled={disabledChip}/>
    </View>);
});
