import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { DateInput } from 'materialTheme/src/theme/components/forminput/DateInput';
import { TicketEntity } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SetTicketShouldCompleteDate, } from 'upmesh-core/src/client/commands/tickets/SetTicketShouldCompleteDate';
import { I18n } from '../../../i18n/I18n';
const getCompletionColor = (ticketStatus, completionOn) => {
    const completionStatus = TicketEntity.getStatusForCompletionOn(ticketStatus, completionOn);
    return completionStatus === 'overdue' ? ThemeManager.style.brandDanger : ThemeManager.style.defaultIconColor;
};
const getQuickSelection = () => {
    const today = new Date();
    return [
        { name: I18n.m.getMessage('today'), days: today.getTime() },
        {
            name: I18n.m.getMessage('tomorrow'),
            days: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0, 0).getTime(),
        },
        {
            name: I18n.m.getMessage('nextWeek'),
            days: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7, 0, 0, 0).getTime(),
        },
        {
            name: I18n.m.getMessage('weeks', { count: 2 }),
            days: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14, 0, 0, 0).getTime(),
        },
        {
            name: I18n.m.getMessage('weeks', { count: 4 }),
            days: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 28, 0, 0, 0).getTime(),
        },
    ];
};
export const TicketCompletionDateChip = React.memo((props) => {
    const [disabledChip, setDisabledChip] = useState(false);
    useEffect(() => {
        if (props.ticketId != null) {
            const c = new SetTicketShouldCompleteDate({}, props.ticketId);
            c.canI()
                .then((result) => {
                if (result === true) {
                    setDisabledChip(false);
                }
                else {
                    setDisabledChip(true);
                }
            })
                .catch((err) => {
                console.debug(err);
                setDisabledChip(true);
            });
        }
        else {
            setDisabledChip(true);
        }
    }, [props.ticketId]);
    const changeCompletionOn = (newDate) => {
        if (props.ticketId != null) {
            const c = new SetTicketShouldCompleteDate({ completionOn: newDate }, props.ticketId);
            c.execute().catch((err) => {
                props.errorResult(err);
            });
        }
    };
    const quickSelection = useRef(getQuickSelection());
    return (<View>
        <View style={props.labelStyle} key="CompletionLabel">
          <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
            {I18n.m.getMessage('ticketsDetailsCompletionOn')}
          </MaterialText>
        </View>
        <DateInput key={`CompletionOn_${getCompletionColor(props.ticketStatus, props.completionOn)}`} labelText={I18n.m.getMessage('ticketsDetailsCompletionOn')} textColor={getCompletionColor(props.ticketStatus, props.completionOn)} onChange={changeCompletionOn} selectedDate={props.completionOn != null ? new Date(props.completionOn) : undefined} preSelectedDate={props.completionOn != null
            ? undefined
            : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())} quickSelections={quickSelection.current} placeholder={I18n.m.getMessage('date')} selectTime={!props.hideTime} disabled={disabledChip} iconWidth={32} iconHeight={32}/>
      </View>);
});
