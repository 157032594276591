import React from 'react';
import { View } from 'react-native';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { StoredStartPages } from 'materialTheme/src/utils/StoredStartPages';
import { I18n } from '../../i18n/I18n';
import { ProjectImage } from '../project/ProjectImage';
export const TicketProjectTitle = React.memo((props) => {
    const goToProject = () => {
        Routing.instance.goTo(`/projects/${props.project.id}/${StoredStartPages.getStoredProjectStartPage()}`);
    };
    return (<View style={{ marginBottom: 16, width: '100%' }}>
        <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('project')}</MaterialText>
        <Chip thumbnail={<ProjectImage size={24} project={props.project}/>} title={props.project.title} onPressChip={goToProject}/>
      </View>);
}, (prevProps, nextProps) => {
    return prevProps.project.title === nextProps.project.title;
});
