import { FilterChips } from 'materialTheme/src/theme/components/chips/FilterChips';
import { ScrollContainer } from 'materialTheme/src/theme/components/scroll/ScrollContainer';
import { ScrollHandler } from 'materialTheme/src/theme/components/scroll/ScrollHandler';
import { UploadingList } from 'materialTheme/src/theme/components/upload/UploadingList';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { LogBookEntity } from 'upmesh-core/src/client/query/entities/LogBookEntity';
import { TicketCommentsEntity } from 'upmesh-core/src/client/query/entities/TicketCommentsEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { LogBookItem } from '../../logbook/LogBookItem';
import { ActivitiesInput } from './ActivitiesInput';
import { ActivitiesItem } from './ActivitiesItem';
export class ActivitiesList extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.gotoNewPosition = false;
        this.lastContentHeight = 0;
        this.listEndReached = false;
        this.loading = false;
        this.scrolledToEnd = true;
        this.skip = 0;
        this.init = async () => {
            const storedFilter = SimpleStorage.get('ticketActivitiesFilter');
            const { filter } = this.state;
            if (storedFilter != null) {
                if (storedFilter.indexOf('comments') > -1)
                    filter.comments = false;
                if (storedFilter.indexOf('history') > -1)
                    filter.history = false;
                if (storedFilter.indexOf('files') > -1)
                    filter.files = false;
            }
            this.mounted = true;
            this.setState({ filter: { ...filter } }, () => {
                this.getComments();
            });
            this.attachKey = UpmeshClient.eventDispatcher.attach({
                readModelName: 'TicketComments',
                callback: (en) => {
                    let update = false;
                    const { comments, filter } = this.state;
                    const newComments = [...comments];
                    en.entities.forEach((e) => {
                        if (e.entity != null && e.entity.ticketId === this.props.ticketId) {
                            const index = comments.findIndex((t) => t.id === e.entity?.id);
                            if (index === -1) {
                                let add = true;
                                if (!filter.comments || !filter.files || !filter.history) {
                                    if (e.entity.commentType === 'logbook' && !filter.history) {
                                        add = false;
                                    }
                                    else if (e.entity.commentType === 'usercomment' && e.entity.attachedFileId != null && !filter.files) {
                                        add = false;
                                    }
                                    else if (e.entity.commentType === 'usercomment' &&
                                        e.entity.attachedFileId == null &&
                                        !filter.comments)
                                        add = false;
                                }
                                if (add) {
                                    update = true;
                                    this.skip += 1;
                                    newComments.push(e.entity);
                                }
                            }
                            else {
                                update = true;
                                newComments[index] = e.entity;
                            }
                        }
                    });
                    if (update) {
                        this.setState({ comments: newComments }, () => {
                            if (this.scrolledToEnd || newComments[newComments.length - 1].createdBy === CurrentUser.userId)
                                setTimeout(() => ScrollHandler.scrollToEnd({
                                    containerId: `activitiesList_${this.props.ticketId}`,
                                    animated: false,
                                }), 200);
                        });
                    }
                },
            });
        };
        this.changeFilter = (_ind, chip) => {
            const { filter } = this.state;
            let storedFilter = SimpleStorage.get('ticketActivitiesFilter');
            if (storedFilter == null)
                storedFilter = '';
            if (chip.checked === true) {
                storedFilter = storedFilter.replace(chip.data, '');
            }
            else {
                storedFilter += chip.data;
            }
            this.skip = 0;
            filter[chip.data] = chip.checked;
            if (!filter.history && !filter.files && !filter.comments) {
                filter.files = true;
                filter.history = true;
                filter.comments = true;
                storedFilter = '';
            }
            SimpleStorage.set('ticketActivitiesFilter', storedFilter);
            this.scrolledToEnd = true;
            this.setState({ comments: [], filter: { ...filter } }, () => {
                this.getComments();
            });
        };
        this.contentSizeChanged = (_contentWidth, _contentHeight) => {
            const { ticketId } = this.props;
            const containerId = `activitiesList_${ticketId}`;
            if (this.gotoNewPosition) {
                const y = _contentHeight - this.lastContentHeight;
                ScrollHandler.scrollTo({ containerId, animated: false, x: 0, y });
                if (this.positionTO != null)
                    clearTimeout(this.positionTO);
                this.positionTO = setTimeout(() => {
                    this.gotoNewPosition = false;
                }, 250);
            }
        };
        this.getComments = () => {
            this.loading = true;
            if (this.updateTimeOut != null)
                clearTimeout(this.updateTimeOut);
            this.updateTimeOut = setTimeout(() => {
                this.getCommentsNow().catch((e) => console.debug('cant update Ticket Comments', e));
            }, 100);
        };
        this.getCommentsNow = async () => {
            const { ticketId } = this.props;
            const { comments, filter } = this.state;
            const top = this.skip > 0 ? 20 : 25;
            if (ticketId != null && ticketId.length > 0) {
                const commentFilter = [];
                if (!filter.comments || !filter.files || !filter.history) {
                    if (filter.history)
                        commentFilter.push(`commentType eq 'logbook'`);
                    if (filter.comments && filter.files)
                        commentFilter.push(`commentType eq 'usercomment'`);
                    else if (filter.files)
                        commentFilter.push(`contains(attachedFileId,'-')`);
                    else if (filter.comments)
                        commentFilter.push(`commentType eq 'usercomment' and attachedFileId eq null`);
                }
                const ticketComments = await UpmeshClient.instance.modals.ticketComments.get({
                    top,
                    filter: commentFilter.length === 1
                        ? `ticketId eq '${ticketId}' and ${commentFilter.join(' or ')}`
                        : commentFilter.length > 1
                            ? `ticketId eq '${ticketId}' and (${commentFilter.join(' or ')})`
                            : `ticketId eq '${ticketId}'`,
                    skip: this.skip,
                });
                this.skip += top;
                ticketComments.sort(TicketCommentsEntity.sortWithClientCreationAndCreatedAt('DESC'));
                if (ticketComments.length > 0) {
                    const allComments = [...comments, ...ticketComments];
                    allComments.sort((a, b) => {
                        const aClientCreation = a['clientCreation'] == null ? a.createdAt : a['clientCreation'];
                        const bClientCreation = b['clientCreation'] == null ? b.createdAt : b['clientCreation'];
                        const date1 = typeof aClientCreation === 'number' ? new Date(aClientCreation) : aClientCreation;
                        const date2 = typeof bClientCreation === 'number' ? new Date(bClientCreation) : bClientCreation;
                        return date1.getTime() - date2.getTime();
                    });
                    this.setState({ comments: allComments }, () => {
                        this.loading = false;
                        if (this.scrolledToEnd)
                            ScrollHandler.scrollToEnd({ containerId: `activitiesList_${ticketId}`, animated: false });
                    });
                    this.listEndReached = false;
                }
                else {
                    this.listEndReached = true;
                }
            }
        };
        this.onScroll = (e) => {
            const end = Math.ceil(e.nativeEvent.contentOffset.y) + Math.ceil(e.nativeEvent.layoutMeasurement.height);
            this.scrolledToEnd = end >= Math.floor(e.nativeEvent.contentSize.height);
            if (!this.listEndReached && !this.gotoNewPosition && !this.loading && e.nativeEvent.contentOffset.y <= 48) {
                this.loading = true;
                this.lastContentHeight = e.nativeEvent.contentSize.height;
                this.gotoNewPosition = true;
                this.getCommentsNow().catch((err) => console.debug('cant load comments', err));
            }
        };
        this.state = {
            comments: [],
            filter: { history: true, comments: true, files: true },
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.fileIdCounter !== this.props.fileIdCounter) {
            const { filter } = this.state;
            if (!filter.files) {
                this.changeFilter(0, { title: '', checked: true, data: 'files' });
            }
        }
    }
    componentWillUnmount() {
        this.mounted = false;
        if (this.attachKey != null) {
            UpmeshClient.eventDispatcher.detach('TicketComments', this.attachKey);
            this.attachKey = undefined;
        }
    }
    get filterChips() {
        const { filter } = this.state;
        return [
            {
                title: I18n.m.getMessage('ticketComments'),
                data: 'comments',
                checked: filter.comments,
            },
            {
                title: I18n.m.getMessage('ticketHistory'),
                data: 'history',
                checked: filter.history,
            },
            {
                title: I18n.m.getMessage('ticketFiles'),
                data: 'files',
                checked: filter.files,
            },
        ];
    }
    render() {
        const { addAttachment, currentKeyboardHeight, fullscreen, contentHeight, contentWidth, ticketId, projectId, uploadFile, inSidebar, } = this.props;
        return (<View key="rightView" style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                backgroundColor: '#F6F6F8',
            }}>
        <ScrollContainer key="activitiesScrollView" style={{
                flexShrink: 0,
                flexGrow: 0,
                position: 'relative',
                flex: undefined,
                width: contentWidth,
                height: fullscreen ? contentHeight - 60 : contentHeight - 72,
            }} onContentSizeChange={this.contentSizeChanged} contentContainerStyle={{ padding: 0 }} onScroll={this.onScroll} scrollEventThrottle={33} containerId={`activitiesList_${ticketId}`}>
          <View style={{
                width: '100%',
                padding: ThemeManager.style.contentPaddingValue,
                paddingTop: 64,
            }}>
            {this.renderActivities()}
            {this.renderUploads()}
          </View>
        </ScrollContainer>
        <View style={{
                position: 'absolute',
                width: '100%',
                top: 8,
                backgroundColor: 'transparent',
            }}>
          <FilterChips backgroundColor="#FFFFFF" backgroundColorChecked="#FFFFFF" chips={this.filterChips} outlined onPressChip={this.changeFilter} centeredChips/>
        </View>
        <View style={{
                position: 'absolute',
                width: contentWidth,
                bottom: fullscreen || inSidebar
                    ? currentKeyboardHeight - 10
                    : Math.max(0, currentKeyboardHeight - 0.1 * ResizeEvent.current.contentHeight),
                borderColor: ThemeManager.style.borderColor,
                borderStyle: 'solid',
                borderWidth: 0,
                borderTopWidth: ThemeManager.style.borderWidth,
                backgroundColor: '#FFFFFF',
                minHeight: 72,
                maxHeight: '90%',
                height: 'auto',
            }}>
          <ActivitiesInput addAttachment={addAttachment} ticketId={ticketId} projectId={projectId} width={contentWidth} uploadFile={uploadFile} onFocus={() => {
                if (this.scrolledToEnd)
                    setTimeout(() => {
                        ScrollHandler.scrollToEnd({ containerId: `activitiesList_${ticketId}`, animated: false });
                    }, 200);
            }}/>
        </View>
      </View>);
    }
    renderActivities() {
        const a = [];
        const { comments } = this.state;
        for (let i = 0; i < comments.length; i += 1) {
            if (comments[i].commentType === 'usercomment') {
                const acti = comments[i];
                a.push(<ActivitiesItem index={i} key={`acti_${acti.id}`} activitie={acti}/>);
            }
            else {
                const lb = new LogBookEntity({
                    entityType: 'Ticket',
                    ...comments[i],
                    ...comments[i].data,
                });
                a.push(<LogBookItem key={`lb_${lb.id}`} paddingBottom={24} withUserImage={false} centered withLink={false} logbookItem={lb} padding={0} deep="ticket"/>);
            }
        }
        return a;
    }
    renderUploads() {
        const { ticketId } = this.props;
        return <UploadingList type={`ticket_${ticketId}`}/>;
    }
}
