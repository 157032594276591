import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { CustomFieldProgressOptions, } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { ChangeTicketCustomField } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCustomField';
import { SliderV2 } from 'materialTheme/src/theme/components/SliderV2';
const getValue = (tValue, o) => {
    if (tValue != null && typeof tValue === 'number') {
        return tValue;
    }
    if (o.default != null) {
        return o.default;
    }
    return 0;
};
export const TicketProgress = React.memo((props) => {
    const o = new CustomFieldProgressOptions(props.progressField?.customField?.options);
    const [value, setValue] = useState(0);
    useEffect(() => {
        if (props.ticketFields != null) {
            props.ticketFields.forEach((field) => {
                if (field.id === props.progressField.id && field.value !== value) {
                    setValue(getValue(field.value, o));
                }
            });
        }
    }, [props.ticketFields]);
    const onChangeSlider = (v) => {
        setValue(v);
        if (props.ticketId != null) {
            const c = new ChangeTicketCustomField({ fieldId: props.progressField.id, value: v }, props.ticketId);
            c.execute().catch((err) => props.errorResult(err));
        }
    };
    return (<>
        <View key="ProgressLabel" style={props.labelStyle}>
          <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
            {props.progressField.label}
          </MaterialText>
        </View>
        <SliderV2 showValue onRelease={onChangeSlider} value={value} minimumValue={o.start ? o.start : 0} maximumValue={o.end ? o.end : 10} disabled={!props.editable} stepWidth={o.stepWidth ? o.stepWidth : 1}/>
      </>);
});
