import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { FilterChips } from 'materialTheme/src/theme/components/chips/FilterChips';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { BackHandler, View } from 'react-native';
import { TeammemberFilter } from 'upmesh-core/src/client/query/filter/TeammemberFilter';
import { I18n } from '../../i18n/I18n';
export class TeamDialogFilter extends PureComponent {
    constructor(props) {
        super(props);
        this.calculateFiltered = () => {
            const { teammembers } = this.props;
            this.setState({ calculateAmount: true }, () => {
                try {
                    const filter = this.createCurrentFilter();
                    const filtered = TeammemberFilter.filterTeammember(teammembers, filter);
                    this.setState({ calculateAmount: false, amountSelected: filtered.length });
                }
                catch (e) {
                    this.setState({ calculateAmount: false, amountSelected: 0 });
                }
            });
        };
        this.init = () => {
            const { teammembers, currentFilter } = this.props;
            const companies = [];
            const roles = [];
            const selectedRolesChips = [];
            const selectedCompaniesChips = [];
            let selectedCompanies = [];
            let selectedRoles = [];
            let selectedStatus = [];
            if (currentFilter != null) {
                for (let i = 0; i < teammembers.length; i += 1) {
                    const member = teammembers[i];
                    if (member.company != null &&
                        member.company.length > 0 &&
                        companies.findIndex((chip) => chip.title === member.company) === -1) {
                        companies.push({ title: member.company, id: member.company });
                    }
                    if (member.translatedRole != null && roles.findIndex((chip) => chip.title === member.translatedRole) === -1) {
                        roles.push({ title: member.translatedRole, id: member.translatedRole });
                    }
                }
                selectedCompanies = currentFilter && currentFilter.companies ? currentFilter.companies : [];
                selectedRoles = currentFilter && currentFilter.roles ? currentFilter.roles : [];
                selectedStatus = currentFilter && currentFilter.status ? currentFilter.status : [];
                companies.forEach((element) => {
                    if (selectedCompanies.findIndex((selected) => selected === element.title) > -1) {
                        selectedCompaniesChips.push(element);
                    }
                });
                roles.forEach((element) => {
                    if (selectedRoles.findIndex((selected) => selected === element.title) > -1) {
                        selectedRolesChips.push(element);
                    }
                });
            }
            const status = [
                {
                    title: I18n.m.getMessage('teamMemberActive'),
                    data: 'accepted',
                    checked: selectedStatus.includes('accepted'),
                },
                { title: I18n.m.getMessage('teamMemberInvited'), data: 'invited', checked: selectedStatus.includes('invited') },
                { title: I18n.m.getMessage('teamMemberRemoved'), data: 'deleted', checked: selectedStatus.includes('deleted') },
            ];
            this.setState({
                companies,
                roles,
                loading: false,
                selectedCompanies,
                selectedStatus,
                selectedRoles,
                status,
                selectedCompaniesChips,
                selectedRolesChips,
            }, this.calculateFiltered);
        };
        this.onChangeCompanies = (companies) => {
            const newSelectedCompanies = [];
            companies.forEach((element) => newSelectedCompanies.push(element.title));
            this.setState({ selectedCompanies: newSelectedCompanies, selectedCompaniesChips: companies }, () => this.calculateFiltered());
        };
        this.onChangeRoles = (roles) => {
            const newSelectedRoles = [];
            roles.forEach((element) => {
                if (element != null && element.title != null)
                    newSelectedRoles.push(element.title);
            });
            this.setState({ selectedRoles: newSelectedRoles, selectedRolesChips: roles }, this.calculateFiltered);
        };
        this.onPressStatusChip = (index, chip) => {
            const { selectedStatus, status } = this.state;
            const newStatusChips = status.slice();
            newStatusChips[index].checked = chip.checked;
            const newStatus = selectedStatus.slice();
            const chipIndex = selectedStatus.findIndex((element) => element === chip.data);
            if (chipIndex === -1) {
                newStatus.push(chip.data);
            }
            else {
                newStatus.splice(chipIndex, 1);
            }
            this.setState({ selectedStatus: newStatus, status: newStatusChips }, () => this.calculateFiltered());
        };
        this.onReset = () => {
            this.resetFilters();
        };
        this.onSelect = () => {
            const { callback } = this.props;
            const filter = this.createCurrentFilter();
            Dialog.instance?.close(() => {
                Routing.instance.changeQueryParameter({
                    f: JSON.stringify(filter),
                });
                callback(filter);
            });
        };
        this.state = {
            companies: [],
            roles: [],
            loading: true,
            selectedRoles: [],
            selectedCompanies: [],
            selectedRolesChips: [],
            selectedStatus: [],
            amountSelected: 0,
            calculateAmount: true,
            selectedCompaniesChips: [],
            status: [
                { title: I18n.m.getMessage('teamMemberActive'), data: 'accepted', checked: false },
                { title: I18n.m.getMessage('teamMemberInvited'), data: 'invited', checked: false },
                { title: I18n.m.getMessage('teamMemberRemoved'), data: 'deleted', checked: false },
            ],
        };
    }
    static open(teammembers, callback, currentFilter) {
        if (Dialog.instance != null) {
            const content = <TeamDialogFilter teammembers={teammembers} callback={callback} currentFilter={currentFilter}/>;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content,
                contentPadding: false,
                scrollable: false,
                fullscreenResponsive: true,
            });
        }
    }
    componentDidMount() {
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
            Dialog.instance?.close();
            return true;
        });
        this.init();
    }
    componentWillUnmount() {
        if (this.backHandler)
            this.backHandler.remove();
    }
    render() {
        const { teammembers } = this.props;
        const { roles, companies, status, loading, amountSelected, selectedCompaniesChips, selectedRolesChips } = this.state;
        if (loading) {
            return (<View style={{ padding: ThemeManager.style.contentPaddingValue }}>
          <Spinner />
        </View>);
        }
        return (<View>
        <DialogTitle>{I18n.m.getMessage('teamFilterHeader')}</DialogTitle>
        <View style={{
                position: 'absolute',
                top: ThemeManager.style.contentPaddingValue,
                right: ThemeManager.style.contentPaddingValue,
            }}>
          <ContainedButton key="ticketFilterModalCloseButton" textColor="#000000" icon={{ icon: 'close' }} onPress={() => Dialog.instance?.close()} backgroundColor="transparent"/>
        </View>
        <View style={{ padding: ThemeManager.style.contentPaddingValue }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('company')}</MaterialText>
          <ChipGroup availableChips={companies} onChanged={this.onChangeCompanies} chips={selectedCompaniesChips}/>
          <View style={{ height: 16 }}/>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('role')}</MaterialText>
          <ChipGroup availableChips={roles} onChanged={this.onChangeRoles} chips={selectedRolesChips}/>
          <View style={{ height: 16 }}/>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('teamMemberStatus')}</MaterialText>
          <FilterChips onPressChip={this.onPressStatusChip} chips={status}/>
        </View>
        <View style={{
                paddingBottom: 8,
                paddingRight: ThemeManager.style.contentPaddingValue,
                paddingLeft: ThemeManager.style.contentPaddingValue,
                width: '100%',
                justifyContent: 'flex-end',
                flexDirection: 'row',
            }}>
          <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('clear')} onPress={this.onReset}/>
          <View style={{ width: 8 }}/>
          <ContainedButton title={`${I18n.m.getMessage('apply')} ${amountSelected}/${teammembers.length}`} onPress={this.onSelect} disabled={amountSelected === 0 && teammembers.length > 0}/>
        </View>
      </View>);
    }
    createCurrentFilter() {
        return {
            roles: this.state.selectedRoles,
            status: this.state.selectedStatus,
            companies: this.state.selectedCompanies,
        };
    }
    resetFilters() {
        this.setState({
            selectedRoles: [],
            selectedCompanies: [],
            selectedStatus: ['accepted', 'invited'],
            selectedCompaniesChips: [],
            selectedRolesChips: [],
            status: [
                { title: I18n.m.getMessage('teamMemberActive'), data: 'accepted', checked: true },
                { title: I18n.m.getMessage('teamMemberInvited'), data: 'invited', checked: true },
                { title: I18n.m.getMessage('teamMemberRemoved'), data: 'deleted', checked: false },
            ],
        }, this.calculateFiltered);
    }
}
