import React, { useContext, useEffect, useRef, useState } from 'react';
import { Linking, Pressable, View } from 'react-native';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { MapMarker } from 'materialTheme/src/theme/components/zoom/MapMarker';
import { TicketStatusColor } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ZoomTileHelper } from 'materialTheme/src/theme/components/zoom/ZoomTileHelper';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import ConnectionContext from 'materialTheme/src/connectionContext';
import { ChangeTicketPlanAffiliation, } from 'upmesh-core/src/client/commands/tickets/ChangeTicketPlanAffiliation';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { I18n } from '../../../i18n/I18n';
import { PlanZoomTileMapper } from '../../plans/PlanZoomTileMapper';
import { OfflineDataDownloader } from '../../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
export const PlanAffiliationThumbs = React.memo((props) => {
    const [plan, setPlan] = useState(null);
    const planVersionRef = useRef(null);
    const [canChangeAffiliation, setCanChangeAffiliation] = useState(false);
    const [info, setInfo] = useState(null);
    const { connectedToServer } = useContext(ConnectionContext);
    const localSynced = plan != null &&
        plan.activePlanId != null &&
        plan.activePlanId !== 'MAP' &&
        OfflineDataDownloader.isPlanVersionSynced(plan.projectId, plan.activePlanId) != null;
    useEffect(() => {
        if (plan != null && plan.activePlanId != null && plan.activePlanId.length > 0) {
            UpmeshClient.instance.modals.planVersion
                .getById(plan.activePlanId)
                .then((versionResult) => {
                planVersionRef.current = versionResult;
                ZoomTileHelper.getTileInfo(`${UpmeshClient.instance.url}/plan/tiles/${props.projectId}/planVersion/${versionResult.id}`)
                    .then((zoomTileResult) => {
                    setInfo(zoomTileResult);
                })
                    .catch((err) => {
                    console.error('cant get zoom tile info', err);
                });
            })
                .catch((err) => {
                console.error('could not load planversion for ticket', err);
            });
        }
    }, [plan]);
    useEffect(() => {
        if (props.planId != null) {
            UpmeshClient.instance.modals.plan
                .getById(props.planId)
                .then((result) => {
                setPlan(result);
            })
                .catch((err) => {
                console.error('could not load plan for ticket', err);
            });
        }
    }, [props.planId]);
    useEffect(() => {
        if (plan != null && plan.activePlanId != null) {
            ZoomTileHelper.getTileInfo(`${UpmeshClient.instance.url}/plan/tiles/${props.projectId}/planVersion/${plan.activePlanId}`)
                .then((tInfo) => {
                setInfo(tInfo);
            })
                .catch((err) => {
                console.warn('could not get tile info', err);
            });
        }
    }, [plan, props.projectId]);
    useEffect(() => {
        const checkCanChangeAffiliationAccessRights = async () => {
            if (props.ticketId !== undefined) {
                const cChange = new ChangeTicketPlanAffiliation({}, props.ticketId, CurrentUser.token);
                cChange
                    .canI()
                    .then((canI) => {
                    if (canI === true) {
                        setCanChangeAffiliation(canI);
                    }
                    else {
                        setCanChangeAffiliation(false);
                    }
                })
                    .catch((err) => {
                    console.debug('no rights to change plan or plan position', err);
                });
            }
        };
        checkCanChangeAffiliationAccessRights().catch((err) => {
            console.warn('cant check rights', err);
        });
    }, [props.ticketId]);
    const label = plan != null && plan.activePlanId === 'MAP'
        ? I18n.m.getMessage('ticketsDetailsMap')
        : I18n.m.getMessage('ticketsDetailsPlan');
    const gotoActivePlanVersion = (x, y, zoom) => (_e) => {
        if (plan != null) {
            Routing.instance.goTo(`/projects/${props.projectId}/tickets/${plan.id}/?zTo=${x}x${y}x${zoom}x${props.ticketId}`);
        }
    };
    const onRef = (r) => {
        if (r != null &&
            props.planId != null &&
            planVersionRef.current == null &&
            props.planPositionX != null &&
            props.planPositionY != null) {
            r.zoomTo({ x: props.planPositionX, y: props.planPositionY }, 15);
        }
    };
    const openUrl = (url) => (_e) => {
        Linking.openURL(url).catch((err) => {
            console.debug('cant open link', err);
        });
    };
    const renderPlanThumb = (zoomLevel) => {
        let planThumb = (<View>
          <MaterialText>{I18n.m.getMessage('noPlanAffiliation')}</MaterialText>
        </View>);
        if (plan != null &&
            props.planId != null &&
            props.planPositionX != null &&
            props.planPositionY != null &&
            plan.activePlanId != null &&
            plan.activePlanId.length > 0) {
            const point = planVersionRef.current == null
                ? { x: props.planPositionX, y: props.planPositionY }
                : planVersionRef.current.getPointInPageFromRealpointInCM({
                    x: props.planPositionX,
                    y: props.planPositionY,
                });
            const markers = [];
            let zoom = 16;
            markers.push(new MapMarker(props.ticketId ?? '', TicketStatusColor.getMarkerForStatus(props.ticketStatus, props.completionOn), point, undefined, gotoActivePlanVersion(props.planPositionX, props.planPositionY, zoomLevel != null ? zoomLevel : zoom)));
            let initialPlanPosition;
            if (planVersionRef.current != null) {
                zoom = 3;
                if (info != null) {
                    zoom = info.maxLevel - Math.ceil(info.maxLevel / 2);
                    if (zoom < 1)
                        zoom = 1;
                }
                initialPlanPosition = {
                    center: { point: { ...markers[0].position } },
                    zoom: zoomLevel != null ? zoomLevel : zoom,
                };
            }
            planThumb = (<Pressable style={{
                    width: 256,
                    height: 144,
                    borderStyle: 'solid',
                    borderWidth: ThemeManager.style.borderWidth,
                    borderColor: ThemeManager.style.borderColor,
                }} onPress={gotoActivePlanVersion(props.planPositionX, props.planPositionY, zoomLevel != null ? zoomLevel : zoom)}>
            <PlanZoomTileMapper plan={plan} initialPlanPosition={initialPlanPosition} ref={onRef} type={planVersionRef.current == null ? 'map' : 'plan'} planVersion={planVersionRef.current ?? undefined} zoomAble={false} key={`plan_${planVersionRef.current == null ? `${props.planId}_${props.ticketAddress}` : planVersionRef.current.id}`} marker={markers} offlineData={planVersionRef.current == null
                    ? undefined
                    : OfflineDataDownloader.isPlanVersionSynced(planVersionRef.current.projectId, planVersionRef.current.id)} url={planVersionRef.current == null
                    ? 'map'
                    : `${UpmeshClient.instance.url}/plan/tiles/${props.projectId}/planVersion/${planVersionRef.current.id}`}/>
          </Pressable>);
        }
        return planThumb;
    };
    const renderAddress = () => {
        if (plan?.activePlanId !== 'MAP' || props.ticketAddress == null || props.ticketAddress.length === 0) {
            return null;
        }
        const urlString = `https://www.google.de/maps/dir//'${props.ticketAddress}'/@${props.planPositionY},${props.planPositionX},18z`;
        return (<View style={{
                flexDirection: 'row',
                width: '100%',
                marginBottom: 16,
                marginTop: 8,
                paddingRight: 8,
                justifyContent: 'space-between',
            }}>
          <View style={{ flex: 1, alignSelf: 'center' }}>
            <MaterialText onPress={openUrl(urlString)}>
              {props.ticketAddress}
              <View style={{ width: 6 }}/>
            </MaterialText>
          </View>
          {props.planPositionX != null && props.planPositionY != null ? (<Icon onPress={openUrl(urlString)} icon="directions" color="#FFFFFF" toolTip={I18n.m.getMessage('address')} backgroundColor={ThemeManager.style.brandPrimary}/>) : null}
        </View>);
    };
    const changePlanPosition = (e) => {
        const asyncNow = async () => {
            const error = await DefaultErrorHandler.getProjectErrors(props.projectId);
            if (error) {
                return;
            }
            Routing.instance.openDialog('ticket', {
                id: props.ticketId,
                changePlan: true,
            }, false)(e);
        };
        asyncNow().catch((err) => console.error(err));
    };
    return (<View style={{ width: '100%', position: 'relative', minHeight: 64 }}>
        <View style={props.labelStyle}>
          <MaterialText type={MaterialTextTypes.Caption}>{label}</MaterialText>
        </View>
        {plan != null &&
            props.planId != null &&
            props.planPositionX != null &&
            plan?.activePlanId !== 'MAP' &&
            props.planPositionY != null ? (<View style={{ paddingBottom: 8 }}>{renderPlanThumb(0)}</View>) : undefined}
        {renderPlanThumb()}
        {plan ? (<View accessibilityLabel="planTitle" style={{ paddingTop: 8 }}>
            <MaterialText>{plan.title}</MaterialText>
          </View>) : undefined}
        {renderAddress()}
        <View style={{
            position: 'absolute',
            top: 4,
            right: 0,
        }}>
          {!canChangeAffiliation ? undefined : (<Icon key="editPlan" icon="pencil-outline" onPress={changePlanPosition} toolTip={I18n.m.getMessage('changeTicketPlanPosition')} disabled={!canChangeAffiliation || (!connectedToServer && !localSynced)}/>)}
        </View>
      </View>);
});
