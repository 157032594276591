import React, { useEffect, useRef } from 'react';
import { View } from 'react-native';
import { ScrollContainer } from 'materialTheme/src/theme/components/scroll/ScrollContainer';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import _ from 'lodash';
import { ChangeTicketCustomField } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCustomField';
import { TicketTextInputs } from './ticketdetails/TicketTextInputs';
import { TicketProjectTitle } from './TicketProjectTitle';
import { TicketTypeChip } from './ticketdetails/TicketTypeChip';
import { CustomFields } from './ticketdetails/CustomFields';
import { TicketBuilder } from './ticketdetails/TicketBuilder';
export const TicketDetailsEditable = React.memo((props) => {
    const loadTicketLayout = () => {
        if (props.ticketLayout != null) {
            return props.ticketLayout;
        }
        return ChangeTicketCustomField.getTicketLayout(props.ticket, props.project);
    };
    const ticketLayout = useRef(loadTicketLayout());
    useEffect(() => {
        ticketLayout.current = loadTicketLayout();
    }, [props.project.ticketLayouts, props.ticket.id]);
    const labelStyle = { marginTop: ThemeManager.style.gap, width: '100%' };
    return (<View>
        <ScrollContainer containerId="ticketDetails" key="ticketDetailsScroll" style={{ width: props.contentWidth, height: props.contentHeight, maxHeight: props.contentHeight }} contentContainerStyle={{ padding: ThemeManager.style.contentPaddingValue }}>
          {props.showProject ? <TicketProjectTitle project={props.project}/> : null}
          <View>
            <TicketTextInputs ticketTitle={props.ticket.title} ticketId={props.ticketId} errorResult={props.errorResult}/>
            <TicketTypeChip type={props.ticket.type} ticketId={props.ticketId} projectId={props.project.id} errorResult={props.errorResult}/>
            <CustomFields labelStyle={labelStyle} errorResult={props.errorResult} ticketId={props.ticketId} ticket={props.ticket} projectId={props.project.id} ticketLayout={ticketLayout.current}/>
            <TicketBuilder labelStyle={labelStyle} ticket={props.ticket}/>
            <View style={{ height: ThemeManager.style.gap }}/>
          </View>
        </ScrollContainer>
      </View>);
}, _.isEqual);
