import { ReadModels } from 'cqrs-core/src/core/query/ReadModels';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { MaterialTextEditableV2 } from 'materialTheme/src/theme/components/text/MaterialTextEditableV2';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { PureComponent } from 'react';
import { Pressable, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { EditTicketComment } from 'upmesh-core/src/client/commands/ticketcomments/EditTicketComment';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { UserOrCompany } from 'upmesh-core/src/server/webserver/UserOrCompany';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import { I18n } from '../../../i18n/I18n';
import { CompanyLogo } from '../../companies/CompanyLogo';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { StoredFilesThumb } from '../../file/StoredFilesThumb';
import { ProjectMemberDetailsPU } from '../../team/ProjectMemberDetailsPU';
export class ActivitiesItem extends PureComponent {
    constructor(props) {
        super(props);
        this.getUserInfo = async () => {
            const { activitie } = this.props;
            if (activitie.creator != null && activitie.creator.length > 0) {
                await DateLocale.waitFor(() => UpmeshClient.instance.ready);
                try {
                    const user = await UserOrCompany.getById(activitie.creator);
                    let userImage = user != null ? <UserImage user={user} size={24}/> : null;
                    if (user != null && user.id.startsWith('company')) {
                        const companies = ReadModels.get('company');
                        const companyId = user.id.slice(7);
                        const company = await companies.getById(companyId);
                        userImage = <CompanyLogo company={company} round={false} size={24}/>;
                    }
                    this.setState({ user, userImage }, this.forceUpdate);
                }
                catch (e) {
                    ErrorReporter.sendReport({ subject: 'user not found', data: e, type: 'warn' });
                    Routing.instance.alert.post({ text: I18n.m.getMessage('userNotFound') });
                }
            }
        };
        this.changeComment = (activitie) => (text) => {
            const a = new EditTicketComment({ comment: text }, activitie.id);
            a.execute().catch((e) => {
                ErrorReporter.sendReport({ subject: 'cant change comment', data: e, type: 'warn' });
                Routing.instance.alert.post({
                    text: `${I18n.m.getMessage('ticketsDetailsChangeCommentError')} ${DefaultErrorHandler.getDefaultErrorMessages(e)}`,
                });
            });
        };
        this.onLayout = (e) => {
            if (this.state.imageWidth !== e.nativeEvent.layout.width) {
                this.setState({
                    imageWidth: e.nativeEvent.layout.width - ThemeManager.style.getScreenRelativePixelSize(16),
                });
            }
        };
        this.openUser = (userId) => (e) => {
            if (userId != null) {
                const { activitie } = this.props;
                UpmeshClient.instance.modals.projectUser
                    .get({
                    filter: `projectId eq '${activitie.projectId}' and userId eq '${userId}'`,
                })
                    .then((projectUser) => {
                    const projectUserId = projectUser.length === 0 ? `creator_${userId}` : projectUser[0].id;
                    ProjectMemberDetailsPU.openMemberDetails(projectUserId, userId, activitie.projectId)(e);
                })
                    .catch(DefaultErrorHandler.showDefaultErrorAlert);
            }
        };
        this.state = { imageWidth: 256 };
    }
    componentDidMount() {
        this.getUserInfo().catch((err) => console.debug(err));
    }
    renderText(activity) {
        const { activitie } = this.props;
        if (activity.comment == null || (activity.attachedFileId != null && activity.attachedFileId.length > 0)) {
            return null;
        }
        let placeholder = I18n.m.getMessage('ticketsDetailsAddComment');
        let textType = MaterialTextTypes.Body1;
        if (activity.comment.length === 0) {
            placeholder = I18n.m.getMessage('ticketDetailsCommentDeleted');
            textType = MaterialTextTypes.Caption;
        }
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
        if (activity.creator === CurrentUser.userId &&
            ((activity.clientCreation != null && activity.clientCreation > yesterday) ||
                (activity.clientCreation == null && activity.createdAt > yesterday))) {
            return (<MaterialTextEditableV2 key={`MTE${activity.comment}`} placeholder={placeholder} toolTip={I18n.m.getMessage('tooltipEditTicketComment')} materailTextProps={{
                    type: textType,
                    recognizeUrl: true,
                }} onChanged={this.changeComment(activity)} scrollContainerId={`activitiesList_${activitie.ticketId}`}>
          {activity.comment}
        </MaterialTextEditableV2>);
        }
        return (<MaterialText type={textType} recognizeUrl>
        {activity.comment !== null && activity.comment.length > 0 ? activity.comment : placeholder}
      </MaterialText>);
    }
    render() {
        const { activitie, index } = this.props;
        const { imageWidth, user, userImage } = this.state;
        const username = user != null ? user.getFullName() : '?';
        const creationDate = activitie.clientCreation != null ? activitie.clientCreation : activitie.createdAt;
        const date = `${I18n.m.dateCurrent.localeDateString(creationDate)} - ${I18n.m.dateCurrent.getLocalTimeString(creationDate)}`;
        let image = null;
        if (activitie.attachedFileId != null && activitie.attachedFileId.length > 0) {
            image = (<View key={`outer_file_${activitie.id}`} style={{
                    marginBottom: activitie.comment != null && activitie.comment.length > 0
                        ? ThemeManager.style.getScreenRelativePixelSize(8)
                        : 0,
                }}>
          <StoredFilesThumb border={false} adjustSizeToImageSize key={`file_${activitie.id}`} index={index} width={imageWidth} group="ticket_" height={256} storedFileId={activitie.attachedFileId} activityId={activitie.id}/>
        </View>);
        }
        const showRight = CurrentUser.entity != null && user != null && CurrentUser.entity.id === user.id;
        return (<View style={{
                flexDirection: showRight ? 'row-reverse' : 'row',
                alignSelf: 'stretch',
                overflow: 'visible',
                paddingBottom: 24,
            }}>
        <Pressable onPress={this.openUser(user?.id)} style={{ width: 40 }}>
          {userImage}
        </Pressable>
        <View style={{ flexGrow: 1, flexShrink: 1, alignSelf: 'stretch', overflow: 'visible' }}>
          <View style={{
                flexDirection: 'row',
                alignSelf: 'stretch',
                justifyContent: 'space-between',
            }}>
            <View style={{ flexDirection: 'row' }}>
              <MaterialText onPress={this.openUser(user?.id)} type={MaterialTextTypes.Caption}>
                {showRight ? '' : username}
              </MaterialText>
            </View>
            <MaterialText type={MaterialTextTypes.Caption}>{date}</MaterialText>
          </View>
          <View style={{ flexGrow: 1, flexShrink: 1, alignSelf: 'stretch' }}>
            <View onLayout={this.onLayout} style={{
                width: '100%',
                maxWidth: '100%',
                backgroundColor: showRight ? '#FCFCFC' : '#FFFFFF',
                borderColor: ThemeManager.style.borderColor,
                borderStyle: 'solid',
                borderWidth: ThemeManager.style.borderWidth,
                borderRadius: ThemeManager.style.getScreenRelativePixelSize(12),
                borderTopLeftRadius: showRight ? ThemeManager.style.getScreenRelativePixelSize(12) : 0,
                borderTopRightRadius: showRight ? 0 : ThemeManager.style.getScreenRelativePixelSize(12),
                padding: ThemeManager.style.getScreenRelativePixelSize(8),
            }}>
              {image}
              {this.renderText(activitie)}
            </View>
          </View>
        </View>
      </View>);
    }
}
