import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { EmptyState } from 'materialTheme/src/theme/components/EmptyState';
import React, { PureComponent } from 'react';
import { ScrollView, View } from 'react-native';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CachedEntities } from '../../config/CachedEntities';
import { I18n } from '../../i18n/I18n';
const emptyStateImage = require('../../assets/img/no_running_timetracking.png');
export class CurrentTimeTrackingWidget extends PureComponent {
    constructor(props) {
        super(props);
        this.updateTime = () => {
            const { timeTrackings } = this.state;
            const trackings = [];
            for (const t of timeTrackings) {
                trackings.push({
                    ...t,
                    duration: TimeTrackingEntity.msToTime(TimeTrackingEntity.getRunningTime(t), false, false),
                });
            }
            this.setState({ timeTrackings: trackings });
        };
        this.updateData = async () => {
            const trackings = await UpmeshClient.instance.modals.timeTracking.get({
                filter: `deleted ne true and ends eq null`,
            });
            const timeTrackings = [];
            for (const t of trackings) {
                timeTrackings.push({
                    ...t,
                    duration: TimeTrackingEntity.msToTime(TimeTrackingEntity.getRunningTime(t), false, false),
                    user: t.userId ? CachedEntities.knownUsers.get(t.userId) : undefined,
                    task: t.taskId ? CachedEntities.knownTasks.get(t.taskId) : undefined,
                    member: t.memberId ? CachedEntities.knownCompanyMember.get(t.memberId) : undefined,
                    costCenter: t.costCenterId ? CachedEntities.knownCostCenter.get(t.costCenterId) : undefined,
                });
            }
            this.setState({ timeTrackings });
        };
        this.state = { timeTrackings: [] };
    }
    componentDidMount() {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CurrentTimeTrackingWidget',
            readModelName: 'TimeTracking',
            callback: () => {
                this.updateData().catch((err) => console.error(err));
            },
        });
        this.updateData().catch((err) => console.debug(err));
        if (this.interval != null)
            clearInterval(this.interval);
        this.interval = setInterval(this.updateTime, 1000);
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('TimeTracking', 'CurrentTimeTrackingWidget');
    }
    renderTimeTracking(tracking) {
        return (<View key={`runningTimeTracking${tracking.id}${tracking.duration}`} style={{
                flexDirection: 'row',
                padding: 16,
                borderBottomWidth: ThemeManager.style.borderWidth,
                borderBottomColor: ThemeManager.style.borderColor,
                alignItems: 'center',
                height: 96,
            }}>
        <UserImage user={tracking.user} size={48}/>
        <View style={{ flex: 1, paddingLeft: 8 }}>
          {tracking.user != null || tracking.member != null ? (<MaterialText>
              {tracking.user ? tracking.user.getFullName() : `${tracking.member.firstName} ${tracking.member.lastName}`}
            </MaterialText>) : null}
          <MaterialText type={MaterialTextTypes.Body2} color={ThemeManager.style.black54} numberOfLines={1}>
            {`${tracking.costCenter?.mark} - ${tracking.costCenter?.description}`}
          </MaterialText>
          <MaterialText type={MaterialTextTypes.Body2} color={ThemeManager.style.black54} numberOfLines={1}>{`${tracking.task?.mark} - ${tracking.task?.description}`}</MaterialText>
        </View>
        <View>
          <View style={{ alignSelf: 'flex-end' }}>
            <MaterialText textAlign="right">{tracking.duration}</MaterialText>
          </View>
          <View style={{ alignSelf: 'flex-end' }}>
            <MaterialText type={MaterialTextTypes.Body2} color={ThemeManager.style.black54} textAlign="right">
              {`${I18n.m.getMessage('timeTrackingSince')} ${I18n.m.dateCurrent.getLocalTimeString(new Date(tracking.starts))}`}
            </MaterialText>
          </View>
        </View>
      </View>);
    }
    render() {
        const { timeTrackings } = this.state;
        return (<View style={{ width: '100%', height: '100%' }}>
        <View style={{ padding: 16, paddingBottom: 0, width: '100%' }}>
          <MaterialText type={MaterialTextTypes.H5}>{I18n.m.getMessage('timetrackingsRunningHeadline')}</MaterialText>
        </View>
        {timeTrackings.length > 0 ? (<ScrollView style={{ flex: 1 }} nestedScrollEnabled>
            {timeTrackings.map((t) => this.renderTimeTracking(t))}
          </ScrollView>) : (<EmptyState imageSrc={emptyStateImage} description={I18n.m.getMessage('timetrackingsRunningNoEntriesHelper')}/>)}
      </View>);
    }
}
