import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { ChangeTicketType } from 'upmesh-core/src/client/commands/tickets/ChangeTicketType';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { I18n } from '../../../i18n/I18n';
import { CurrentProject } from '../../project/CurrentProject';
export const TicketTypeChip = React.memo((props) => {
    const [typeList, setTypeList] = useState([]);
    const [typeIndex, setTypeIndex] = useState(0);
    const [disabledChip, setDisabledChip] = useState(false);
    const getCorrectProject = async () => {
        const current = CurrentProject.instance.getCurrentProject();
        if (current != null && current.id === props.projectId) {
            return current;
        }
        return UpmeshClient.instance.modals.project.getById(props.projectId);
    };
    const getTypeList = async () => {
        const project = await getCorrectProject();
        const ticketTypes = project != null ? project.ticketTypes : [];
        const list = [];
        ticketTypes?.forEach((s) => {
            list.push({
                title: s,
                onPressChipData: s,
                textColor: ThemeManager.style.defaultTextColor,
            });
        });
        list.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
        return list;
    };
    useEffect(() => {
        getTypeList()
            .then((result) => {
            setTypeList(result);
        })
            .catch((err) => {
            console.error('could not generate tpye list for ticket', err);
        });
    }, [props.projectId]);
    useEffect(() => {
        const currentTypeIndex = typeList.findIndex((val) => val.onPressChipData === props.type);
        setTypeIndex(currentTypeIndex);
    }, [typeList, props.type]);
    useEffect(() => {
        if (props.ticketId != null) {
            const cType = new ChangeTicketType({
                ticketType: props.type == null ? null : props.type,
            }, props.ticketId);
            cType
                .canI()
                .then((result) => {
                if (result === true) {
                    setDisabledChip(false);
                }
                else {
                    setDisabledChip(true);
                }
            })
                .catch((_err) => {
                setDisabledChip(true);
            });
        }
        else {
            setDisabledChip(true);
        }
    }, [props.type, props.ticketId, props.projectId]);
    const onChangeTypeAsync = async (e) => {
        if (props.ticketId == null)
            return;
        const c = new ChangeTicketType({ ticketType: e == null || e === 0 ? null : e }, props.ticketId);
        const { removedFields } = await c.getRemovedFieldsWithContent();
        if (removedFields.length > 0) {
            const fields = [];
            removedFields.forEach((f) => {
                if (f.customField) {
                    fields.push(f.label);
                }
                else if (f.systemField) {
                    const k = f.systemField === 'assignee'
                        ? 'reportTicketDetailsAssignee'
                        : f.systemField === 'approver'
                            ? 'reportTicketDetailsApprover'
                            : f.systemField === 'tags'
                                ? 'reportTicketDetailsTags'
                                : f.systemField === 'craft'
                                    ? 'reportTicketDetailsCraft'
                                    : f.systemField === 'description'
                                        ? 'reportTicketDetailsDescription'
                                        : f.systemField === 'status'
                                            ? 'reportTicketDetailsStatus'
                                            : f.systemField === 'plan'
                                                ? 'reportTicketDetailsPlan'
                                                : f.systemField === 'completionOn'
                                                    ? 'reportTicketDetailsCompletionOn'
                                                    : 'reportTicketDetailsTags';
                    fields.push(I18n.m.getMessage(k));
                }
            });
            Routing.instance.alert.post({
                title: I18n.m.getMessage('ticketChangeTypeWarningRemovedFieldValuesTitle'),
                text: I18n.m.getMessage('ticketChangeTypeWarningRemovedFieldValuesText', { fields: fields.join(', ') }),
                buttons: [
                    <ContainedButton key="no" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close} backgroundColor="transparent"/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('change')} onPress={() => {
                            Alert.instance?.close();
                            c.execute().catch((err) => props.errorResult(err));
                        }}/>,
                ],
            });
        }
        else {
            await c.execute();
        }
    };
    const onChangeType = (e) => {
        onChangeTypeAsync(e).catch((err) => {
            props.errorResult(err);
            console.error(err);
        });
    };
    return (<View>
        <View>
          <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
            {I18n.m.getMessage('ticketsDetailsType')}
          </MaterialText>
        </View>
        <OpenableChip showDeleteIcon emptyStateChip={{
            title: I18n.m.getMessage('ticketTypeNotSelected'),
            onPressChipData: 0,
            textColor: ThemeManager.style.defaultTextColor,
        }} key="typeChip" dialogTitle={I18n.m.getMessage('ticketsDetailsType')} accessibilityLabel="type" elevation={40} selected={typeIndex} onPressChip={onChangeType} widthOpen={256} chipsList={typeList} chipDisabled={disabledChip}/>
      </View>);
});
