"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangePassword = void 0;
var PasswordStrength_1 = require("../../../core/passwords/PasswordStrength");
var UserSetPassword_1 = require("../../events/user/UserSetPassword");
var ClientUserCommands_1 = require("./root/ClientUserCommands");
var ChangePassword = function (_ClientUserCommands_) {
  function ChangePassword(data) {
    var _this;
    (0, _classCallCheck2.default)(this, ChangePassword);
    _this = _callSuper(this, ChangePassword, [data]);
    _this.commandName = 'ChangePassword';
    _this.waitForResult = false;
    _this.aggregate = 'user';
    return _this;
  }
  (0, _inherits2.default)(ChangePassword, _ClientUserCommands_);
  return (0, _createClass2.default)(ChangePassword, [{
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'newPassword') {
          if (this.data.newPassword.length <= 0) {
            throw {
              key: 'newPassword',
              messageCode: 'passwordErrorRequirePw',
              message: 'Neues Passwort wird benötigt'
            };
          }
          if (PasswordStrength_1.PasswordStrength.checkPassword(this.data.newPassword)[0] < 0.65) {
            throw {
              key: 'newPassword',
              messageCode: 'passwordErrorPwWeak',
              message: 'Passwort zu schwach'
            };
          }
        }
        if (key == null || key === 'oldPassword') {
          if (this.data.oldPassword.length <= 0) {
            throw {
              key: 'oldPassword',
              messageCode: 'passwordErrorRequirePw',
              message: 'Altes Passwort wird benötigt'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new UserSetPassword_1.UserSetPassword(this.entityId, {
          userId: this.data.userId,
          passwordHash: this.data.newPassword
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(ClientUserCommands_1.ClientUserCommands);
exports.ChangePassword = ChangePassword;