var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EmptyState = EmptyState;
var _react = _interopRequireDefault(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _MaterialText = require("./text/MaterialText");
var _ThemeManager = require("../ThemeManager");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function EmptyState(_ref) {
  var imageSrc = _ref.imageSrc,
    title = _ref.title,
    description = _ref.description;
  return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
    style: {
      flex: 1,
      justifyContent: 'center',
      gap: 16
    },
    children: [imageSrc && (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        flex: 1,
        width: '100%',
        height: '100%',
        maxHeight: 200
      },
      children: (0, _jsxRuntime.jsx)(_reactNativeWeb.Image, {
        style: {
          width: '100%',
          resizeMode: 'contain',
          height: '100%'
        },
        source: imageSrc
      })
    }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: {
        width: '100%',
        alignItems: 'center',
        paddingBottom: _ThemeManager.ThemeManager.style.contentPaddingValue
      },
      children: [title && (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
        centeredBox: true,
        centeredText: true,
        color: _ThemeManager.ThemeManager.style.black54,
        type: _MaterialText.MaterialTextTypes.Subtitle2,
        strong: true,
        children: title
      }), description && (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
        centeredBox: true,
        centeredText: true,
        color: _ThemeManager.ThemeManager.style.black54,
        type: _MaterialText.MaterialTextTypes.Subtitle2,
        children: description
      })]
    })]
  });
}