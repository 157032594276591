import { DialogUp } from 'materialTheme/src/theme/components/DialogUp';
import React from 'react';
import { TicketEntity } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { TicketDetailsEditable } from '../../../tickets/TicketDetailsEditable';
import { I18n } from '../../../../i18n/I18n';
const getPreviewTicket = (project) => {
    const firstTag = project.tags?.at(0);
    const pT = new TicketEntity();
    pT.type = I18n.m.getMessage('previewTicketType');
    pT.title = I18n.m.getMessage('previewTicketTitle');
    pT.description = I18n.m.getMessage('previewTicketDescription');
    pT.tags = firstTag != null ? [firstTag] : [];
    pT.ticketNumber = 42;
    return pT;
};
function PreviewWrapper(props) {
    return (<TicketDetailsEditable contentWidth={props.size?.contentWidth ?? 0} contentHeight={props.size?.contentHeight ?? 0} project={props.project} errorResult={(_err) => { }} ticket={getPreviewTicket(props.project)} showProject={false} ticketLayout={props.ticketLayout}/>);
}
export const openPreviewDialog = (props) => {
    DialogUp.instance?.open({
        content: <PreviewWrapper {...props}/>,
        closeOnTouchOutside: true,
        scrollable: false,
        fullscreenResponsive: true,
        contentPadding: true,
        showCloseIcon: true,
    });
};
