import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { CustomFieldMultiPesronOptions, } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ChangeTicketCustomField } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCustomField';
import { chipList } from '../../project/settings/ticketlayouts/TicketLayoutMultiPersonFieldOptions';
export const MultiPersonChip = React.memo((props) => {
    const o = new CustomFieldMultiPesronOptions(props.personField?.customField?.options);
    const [selectedChips, setSelectedChips] = useState(o.multiperson && o.multiperson.length > 0 ? chipList().filter((chip) => o.multiperson?.includes(chip.id)) : []);
    useEffect(() => {
        if (props.ticketFields != null) {
            props.ticketFields.forEach((field) => {
                if (field.id === props.personField.id && field.value) {
                    const filteredArr = chipList().filter((item) => field.value.includes(item.id));
                    setSelectedChips(filteredArr);
                }
            });
        }
    }, [props.ticketFields]);
    return (<>
        <View style={props.labelStyle} key="MultiPersonLabel">
          <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
            {props.personField.label}
          </MaterialText>
        </View>
        <ChipGroup key={`added_${true}_${selectedChips.toString()}`} chips={selectedChips} bordered={false} availableChips={chipList()} onChanged={(items) => {
            setSelectedChips(items);
            if (props.ticketId != null) {
                const ids = [];
                items.forEach((item) => {
                    if (item.id)
                        ids.push(item.id);
                });
                if (props.ticketId != null) {
                    const c = new ChangeTicketCustomField({ fieldId: props.personField.id, value: ids }, props.ticketId);
                    c.execute().catch((err) => props.errorResult(err));
                }
            }
        }}/>
      </>);
});
