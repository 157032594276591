import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { AddProjectTag } from 'upmesh-core/src/client/commands/project/tags/AddProjectTag';
import { AddTicketTag } from 'upmesh-core/src/client/commands/tickets/AddTicketTag';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import { RemoveTicketTag } from 'upmesh-core/src/client/commands/tickets/RemoveTicketTag';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { I18n } from '../../../i18n/I18n';
export const TagChips = React.memo((props) => {
    const [projectTags, setProjectTags] = useState([]);
    const [projectTagGroups, setProjectTagGroups] = useState([]);
    const [disabledChips, setDisabledChips] = useState(false);
    const [canAddNewProjectTag, setCanAddNewProjectTag] = useState(false);
    const updateTags = () => {
        const newProjectTags = [];
        const newProjectTagGroups = new Map();
        UpmeshClient.instance.modals.project
            .getById(props.projectId)
            .then((rProject) => {
            rProject.tags?.forEach((t) => {
                newProjectTags.push({
                    id: t.tagName,
                    groupId: t.groupName,
                    title: t.tagName,
                    backgroundColor: t.color,
                });
                if (!newProjectTagGroups.has(t.groupName)) {
                    newProjectTagGroups.set(t.groupName, { backgroundColor: t.color, groupName: t.groupName, id: t.groupName });
                }
            });
            newProjectTags.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
            setProjectTags(newProjectTags);
            setProjectTagGroups([...newProjectTagGroups.values()]);
        })
            .catch((err) => {
            console.debug(err);
        });
    };
    useEffect(() => {
        updateTags();
        RightsManager.hasWriteRight(props.projectId, CurrentUser.userId, 'commandChangeProject')
            .then((rResult) => {
            if (rResult) {
                setCanAddNewProjectTag(true);
            }
            else {
                setCanAddNewProjectTag(false);
            }
        })
            .catch((err) => {
            console.debug(err);
            setCanAddNewProjectTag(false);
        });
    }, [props.projectId]);
    useEffect(() => {
        if (props.ticketId != null) {
            const c = new AddTicketTag({}, props.ticketId);
            c.canI()
                .then((result) => {
                if (result === true) {
                    setDisabledChips(false);
                }
                else {
                    setDisabledChips(true);
                }
            })
                .catch((err) => {
                console.debug(err);
                setDisabledChips(true);
            });
        }
        else {
            setDisabledChips(true);
        }
    }, [props.ticketId]);
    const chipPropsExtendedFromTag = (tags) => {
        const result = [];
        tags?.forEach((t) => {
            result.push({
                title: t.tagName,
                id: t.tagName,
                groupId: t.groupName,
                backgroundColor: t.color,
            });
        });
        return result;
    };
    const saveTags = (e) => {
        const cTicketTags = props.ticketTags ?? [];
        const asyncNow = async () => {
            for (let i = 0; i < e.length; i += 1) {
                if (cTicketTags.findIndex((j) => j.tagName === e[i].id) === -1) {
                    if (projectTags.findIndex((t) => t.title === e[i].title) === -1) {
                        const group = projectTagGroups.find((g) => g.groupName === e[i].groupId);
                        if (group != null) {
                            const newTag = { tagName: e[i].title, groupName: group.groupName, color: group.backgroundColor };
                            const c = new AddProjectTag({ tag: newTag }, props.projectId);
                            try {
                                await c.execute();
                            }
                            catch (e) {
                                if (e.messageCode != null) {
                                    Routing.instance.alert.post({ text: I18n.m.getMessage(e.messageCode) });
                                }
                                else {
                                    ErrorReporter.sendReport({ subject: 'error', data: e, type: 'warn' });
                                }
                            }
                        }
                    }
                    try {
                        const c = new AddTicketTag({ tag: { tagName: e[i].id, color: e[i].backgroundColor, groupName: e[i].groupId } }, props.ticketId);
                        await c.execute();
                    }
                    catch (err) {
                        props.errorResult(err);
                    }
                }
            }
            if (props.ticketTags != null && props.ticketTags.length > 0) {
                for (const t of props.ticketTags) {
                    if (e.findIndex((j) => j.id === t.tagName) === -1) {
                        try {
                            const c = new RemoveTicketTag({ tag: { ...t } }, props.ticketId);
                            await c.execute();
                        }
                        catch (err) {
                            props.errorResult(err);
                        }
                    }
                }
            }
        };
        if (props.ticketId != null) {
            asyncNow().catch((err) => console.error(err));
        }
    };
    const getAddButtons = (text) => {
        const list = [];
        for (let i = 0; i < projectTagGroups.length; i += 1) {
            const group = projectTagGroups[i];
            list.push({
                title: group.groupName,
                secondTextLine: I18n.m.getMessage('tagsAddNewToGroup', { tag: text }),
                titleColor: ThemeManager.style.black87,
                textColor: ThemeManager.style.brandDanger,
                groupId: group.groupName,
                thumbnail: {
                    width: 24,
                    thumbnail: (<View style={{
                            height: 24,
                            width: 24,
                            borderStyle: 'dashed',
                            borderWidth: ThemeManager.style.borderWidth,
                            borderColor: group.backgroundColor,
                            borderRadius: 12,
                        }}>
              <Icon toolTip="" icon="plus" color={group.backgroundColor} outerSize={23}/>
            </View>),
                },
            });
        }
        return list;
    };
    return (<View>
      <View style={props.labelStyle} key="tagsLabel">
        <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
          {I18n.m.getMessage('ticketsDetailsTags')}
        </MaterialText>
      </View>
      <ChipGroup key="ticketTagChips" dialogTitle={I18n.m.getMessage('ticketsDetailsTags')} textColor="#FFFFFF" label="" bordered={false} availableChips={projectTags} chips={chipPropsExtendedFromTag(props.ticketTags)} sortFormByGroup onChanged={saveTags} buttonDisabled={disabledChips} editable={!disabledChips} tooltipAddChip={I18n.m.getMessage('addTags')} chipGroups={projectTagGroups} canAddNewChips={canAddNewProjectTag} addNewButtons={getAddButtons} canAddNewChipsViaSearchbar={false}/>
    </View>);
});
