var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = GuidelineChip;
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _react = _interopRequireDefault(require("../../../../../../upmesh-client/node_modules/react"));
var _MaterialText = require("../../../theme/components/text/MaterialText");
var _Icon = require("../../../theme/components/Icon");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
var SUCCESS_TEXT = '#1ab962';
var SUCCESS_BG = '#d1ffe6';
var FAIL_TEXT = '#b91a1a';
var FAIL_BG = '#ffd1d1';
var DEFAULT_TEXT = '#a8a8a8';
var DEFAULT_BG = '#f5f5f5';
function GuidelineChip(props) {
  var met = props.met,
    text = props.text,
    score = props.score;
  var getBackgroundColor = function getBackgroundColor() {
    if (met == null) return DEFAULT_BG;
    if (!met) return FAIL_BG;
    return SUCCESS_BG;
  };
  var getTextColor = function getTextColor() {
    if (met == null) return DEFAULT_TEXT;
    if (!met) return FAIL_TEXT;
    return SUCCESS_TEXT;
  };
  var getIcon = function getIcon() {
    if (met === null) return 'circle-outline';
    if (!met) return 'close';
    return 'check';
  };
  return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
    style: {
      width: '100%',
      height: 28,
      backgroundColor: getBackgroundColor(),
      borderRadius: 14,
      flexDirection: 'row',
      alignItems: 'center',
      paddingRight: 14
    },
    children: [met !== undefined ? (0, _jsxRuntime.jsx)(_Icon.Icon, {
      icon: getIcon(),
      toolTip: "",
      iconSize: 16,
      color: getTextColor()
    }) : (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        width: 24
      }
    }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
      color: getTextColor(),
      additionalTextStyle: {
        alignSelf: 'center',
        flex: 1
      },
      numberOfLines: 1,
      children: text
    }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
      color: getTextColor(),
      additionalTextStyle: {
        alignSelf: 'center'
      },
      children: score
    })]
  });
}